

.gal__wrapper{
    position: relative;
}
.gal__img{
 
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;
  padding-bottom:0;
 
 
}
.gal__img img{
    width: 100%;
    border-radius: 5%;
    z-index: -1;
}

.gal__caption{
    position: absolute;
  right: 20px;
  top: 5px;
    font-size: 30px;
    background: black;
    padding: 1.5rem;
    color: var(--font-col2);
    opacity: 0.7;
    border-radius: 15px;
    transition: 400ms ease-in;
}
.gal__caption:hover{
    background: var(--bg-col2);
}

@media screen and (max-width:650px) {
    .gal__caption{
        font-size: 18px;
        padding: 1rem;
    }
}
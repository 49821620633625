

.container__home{
    background-image: url("../../assets/homepage.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--font-col2);
    display: flex;
    flex-direction: column;
    row-gap: 3rem;
    position: relative;
    isolation: isolate;
}

.container__home::after{
position: absolute;
content: '';
background-color: black;
z-index: -1;
inset: 0;
opacity: .6;
}
.heading1{
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}
.heading1 h1{
    font-size: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.heading1 h1 span{
    font-style: italic;
    position: relative;
    color: transparent;
    -webkit-text-stroke: 0.1vw var(--font-col2);
}
.heading1 h1 span::before{
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: var(--bg-col2);
    -webkit-text-stroke: 0vw var(--bg-col2);
    border-right: 2px solid white;
    overflow: hidden;
    animation: animate 6s linear forwards;
}
@keyframes animate{
    0%, 10% , 100%{
width: 0;
    }
    70%, 90%{
        width: 100%;
    }
}

.heading1 h3{
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home__but{
    padding: 1rem 1rem;
    font-size: 19px;
    text-transform:uppercase;
    background: var(--bg-col2);
    color: var(--font-col2);
    border: 4px solid var(--border-col);
    border-radius: 40px;
    transition: 1000ms ease-in-out;
    font-weight: bold;
}
.home__but:hover{
    background: transparent;
    cursor: pointer;
    animation: bounce 1500ms ease-in-out;
}
@keyframes bounce{
    0%,
    20%,
    60%,
    100%{
        transform: translateY(0);
    }
    40%{
        transform: translateY(-30px);
    }
    80%{
        transform: translateY(-20px);
    }
}
@media screen and (max-width:901px) {
    .heading1 h1{
        font-size: 45px;
   
    }
    
    
    .heading1 h3{
        font-size: 22px;
   
    }
}
@media screen and (max-width:650px) {
    .heading1 h1{
        font-size: 25px;
   
    }
    
    
    .heading1 h3{
        font-size: 18px;
   
    }
}
@media screen and (max-width:550px) {
    .heading1 h1{
        font-size: 25px;
   
    }
    
    
    .heading1 h3{
        font-size: 15px;
   
    }
}
@media screen and (max-width:400px) {
    .heading1 h1{
        font-size: 25px;
   
    }
    
    
    .heading1 h3{
        font-size: 12px;
   
    }
    .home__but{
        font-size: 15px;
    }
}
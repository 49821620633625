.gallery__head {
  margin-top: 3rem;
    position: relative;
    width: 100%;
    
    background-color:#181820;
    overflow: hidden;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    isolation: isolate;
    height: 15vh;
  }
  .gallery__head::before {
    content: '';
    position: absolute;
   width: 100%;
   top: 0;
   left: 0;
   height: 15%;
    background-color: var(--bg-col2);
 
    
   z-index: -1;
    
  }
.gal__card{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  column-gap: 2rem;
  padding: 4rem 2rem;
 
}
.gallery__info h1{
  margin: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 45px;
}
 .para{
  padding:2rem  8rem;
  font-size: 30px;
}
  .gallery__head::after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 15%;
    background-color: var(--bg-col2);
  }
  .gallery__head h1{
    font-size: 60px;
    color: var(--font-col2);
  }
  .gallery__img{
  
    display: flex;
    align-items: center;
    justify-content: center;
    height: 450px;
    width: 100%;
    background-size: cover;
    overflow: hidden;
    opacity: 1;
    
  }

  .gallery__slide{
    position: relative;
    display: grid;
    grid-template-columns: 1fr 10fr 1fr;
    place-items: center;
    place-content: center;
    height: 70vh;
    width: 100%;
    justify-content: center;
    overflow:hidden;
    object-fit: cover;
    margin-top: 2rem;
    
  }
  .gallery__slide div{

  }
  .image{
 
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    display: flex;
    align-items: center;
    transition-delay: 0ms;
    width: 100%;
  
  }
  .anim{
    
    
   
  } 

.right_arrow{
    position: absolute;
    top: 50%;
    right: 32px;
    font-size: 3rem;
    color: black;
    z-index: 10;
    cursor: pointer;
    user-select: none;
    opacity: 0.8;
    transition: 400ms ease-in-out;
  }
  .left_arrow{
    position: absolute;
    top: 50%;
    left: 32px;
    font-size: 3rem;
    color: black;
    z-index: 10;
    cursor: pointer;
    user-select: none;
    opacity: 0.8;
    transition: 400ms ease-in-out;
  }
  .left_arrow:hover , .right_arrow:hover{
    color: var(--bg-col2);
  }
 .img_side{
  width: 500px;
  height: 280px;
  margin: 0 auto;
 }
 .fade-enter {
  opacity: 0.01;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-exit {
  opacity: 0.5;
}

.fade-exit-active {
  opacity: 0.01;
  transition: opacity 500ms ease-out;
}
.slide-enter {
  opacity: 0;
  transform: translateX(50%);
}

.slide-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms, transform 500ms;
}

.slide-exit {
  opacity: 1;
  transform: translateX(0);
}

.slide-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 500ms, transform 500ms;
}
  @media screen and (max-width:1200px) {
    .right_arrow{
  
    right: 14px;
   
  
  }
  .left_arrow{
  
    left: 14px;
  }

  .gal__card{
  
    grid-template-columns: repeat(2, 1fr);
 
  }
  .gallery__info h1{
    margin: 2rem;
  
    font-size: 45px;
  }
   .para{
    padding:2rem  5rem;
    font-size: 30px;
  }
  }
  @media screen and (max-width:800px) {
    .gallery__head h1{
      font-size: 45px;
    }
    .right_arrow{
  
      right: 4px;
     
    
    }
    .left_arrow{
    
      left: 4px;
    }
    .image{
 
      height: 450px;
    }
    .para{
      padding:1rem  4rem;
      font-size: 22px;
    }
    .gal__card{
  
      grid-template-columns: repeat(1, 1fr);
   
    }
  }
  @media screen and (max-width:700px) {
    .gallery__head h1{
      font-size: 40px;
    }
    .image{
 
      height: 400px;
    }
    .gal__card{
   
      padding: 0.9rem 0.5rem;
     
    }
  }

  @media screen and (max-width:650px) {
    .right_arrow{
  
      right: 24px;
     
    
    }
    .left_arrow{
    
      left: 24px;
    }
    .image{
 
      height: 400px;
    }
    .gallery__slide{
      grid-template-columns: 1fr;
      padding-left: 10px;
      padding-right: 10px;
    }
    .gallery__head h1{
      font-size: 25px;
    }
    .gallery__head {
      margin-top: 1rem;
      height: 10vh;
    }
    .para{
      padding:1rem  0.8rem;
      font-size: 17px;
    }
    .gallery__info h1{
      margin: 1rem;
    
      font-size: 25px;
    }
  }
  
  @media screen and (max-width:550px) {
    .right_arrow{
  
      right: 14px;
     
    
    }
    .left_arrow{
    
      left: 14px;
    }
    .image{
 
      height: 400px;
    }
    .gallery__slide{
     height: 80vh;
    }
    .gal__card{
   
      padding: 0.1rem 0.1rem;
     
    }
    .gallery__slide{
      padding: 0.1rem 0.1rem;
      margin-top: 10px;
    }
  }
  @media screen and (max-width:450px) {
    .right_arrow{
  
      right: 14px;
     
    
    }
    .left_arrow{
    
      left: 14px;
    }
    .image{
 
      height: 300px;
    }
    .gallery__slide{
     height: 70vh;
    }
  }
  @media screen and (max-width:321px) {
    .right_arrow{
  
      right: 14px;
     
    
    }
    .left_arrow{
    
      left: 14px;
    }
    .image{
 
      height: 220px;
    }
    .gallery__slide{
     height: 50vh;
    }
  }
:root{
  --font-typ:  Arial, Helvetica, sans-serif;
  --head-col: rgb(253, 253, 253);
  --foot-col: rgb(253, 253, 253);
  --bg-col: rgb(231, 232, 235);
  --bg-col2:  rgb(214, 8, 77);
  --font-col: #060c20;
  --font-col2: rgb(235, 235, 236);
  --bg-col3:rgb(24, 32, 24);
  --border-col: rgb(214, 8, 77);
  
}


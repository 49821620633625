*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  font-family: var(--font-typ);
  list-style: none;
  
}

body{
  background: var(--bg-col);
  color: var(--font-col);
 
}
a{
  text-decoration: none;
}
.section__margin{
  margin: 2rem;
}
.section__padding{
  padding: 4rem;
  border-radius: 1rem
}

@media screen and (max-width:1200px) {
  .section__margin{
    margin:1rem;
  }
  .section__padding{
    padding: 1rem;
  }
}
a{
  color: unset;
  text-decoration: none;
}

@media screen and ( max-width:650px ) {
  .section__margin{
    margin:5px;
  }
  .section__padding{
    padding: 6px;
  }
  h1{
    font-size: 22px;
  }
  h2{
    font-size: 19px;
  }
  h3{
    font-size: 17px;
  }
  p{
    font-size: 14px;
  }
}

.navbar__container{
    
    position: fixed;
    top: 0;
    color: var(--font-col2);
    width: 100%;
    z-index: 99;
}
 .navbar__main{
    display: grid;
    grid-template-columns: 1fr 2fr;
    padding: 0.5rem 2rem;
    position: relative;
   
 }

 .links ul{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    list-style: none;
  
   
 }
 .links ul li{
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  
    padding: 0.2rem 1rem;
    padding: 1.9rem 1rem;
    transition: 600ms ease-in-out;
 }
 .links ul li:hover{
    cursor: pointer;
    color: orange ;
    width: auto;
 }
.active{
    color:var(--bg-col2) ;
    width: auto;
   
    
}
 .logo{
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
 }
 .logo:hover{
    cursor: pointer;
 }
 .logo img{
    width: 100%;
   filter: invert(100%) 
   sepia(1%) saturate(7499%) 
   hue-rotate(224deg) 
   brightness(113%) 
   contrast(101%)
 }

.toogle__but{
    display: none;
}

@media screen and (max-width:1200px) {
    .logo{
        width: 60%;
       }
    .logo img{
        width: 100%;
    }
    .navbar__main{
        padding: .5rem;
    }
    .links{
        display: none;
    }
    .logo{
       
    }
   
    .toogle__but{
        display: block;
        padding: 1rem 1rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      
       
    }
    
    .toogle__but button{
      
        border: 3px solid whitesmoke;
        font-size: 35px;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: .3rem;
        font-weight: bold;
        border-radius: 5px;
    }
   .close{
    color: red;
   }
    .navbar__links{
        position: absolute;
        display: block;
        top: 125px;
        width: 50%;
        display: flex;
        flex-direction: column;
        row-gap: 3rem;
        background: var(--bg-col3);
        font-size: 25px;
        align-items: center;
        opacity: 0.9;
        
    }
    .navbar__links li{
     border: 4px solid var(--bg-col2);
     width: 100%;
     padding: 2rem 0rem;
     display: flex;
     align-items: center;
     justify-content: center;
    }

    .active{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem 0rem;
    }
    .show_nav{
        display: block;
    }
    .hide_nav{
        display: none;
    }
    .scale-up-hor-right {
        -webkit-animation: scale-up-hor-right 0.7s ease-in-out both;
                animation: scale-up-hor-right 0.7s ease-in-out both;
    }


 @-webkit-keyframes scale-up-hor-right {
    0% {
      -webkit-transform: scaleX(0.4);
              transform: scaleX(0.4);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
    }
    100% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
    }
  }
  @keyframes scale-up-hor-right {
    0% {
      -webkit-transform: scaleX(0.4);
              transform: scaleX(0.4);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
    }
    100% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
    }
  }
}
@media screen and (max-width:800px) {
    .logo{
        width: 70%;
       }

    }

    @media screen and (max-width:600px) {
        .logo{
            width: 90%;
           }
    
        }

        @media screen and (max-width:500px) {
            .logo{
                width: 100%;
               }
               .navbar__links{
                width: 80%;
                font-size: 18px;
               }
            }
        
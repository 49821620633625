.footer__container{
    background-color: #181820;
    color: var(--font-col2);
    padding: 6rem 2rem;
}

.footer__left{
    
        width: 100%;
       filter: invert(100%) 
       sepia(1%) saturate(7499%) 
       hue-rotate(224deg) 
       brightness(113%) 
       contrast(101%);
       display: flex;
       justify-content: center;
       align-items: center;
     
}

.footer__wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 4rem;
}
.footer__left img{
    width: 100%;
}
.footer__center ul li{
    padding: 2rem;
    font-size: 22px;
    transition: 400ms ease-in-out;
}
.footer__center ul li:hover{
    background: var(--bg-col2);
    cursor: pointer;
}
.foot__desc{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0.9rem;
    border-top: 3px solid var(--font-col2);
    padding-top: 0.5rem;
}
.footer__desc{
    display: flex;
    
    margin-bottom: 1.5rem;
    border-bottom: 3px solid var(--font-col2);
    padding-bottom: 1rem;
    white-space: wrap;
}
@media screen and (max-width:1200px) {
    .footer__wrapper{
      
        grid-template-columns: 1fr 1fr;
      
    }
    .footer__desc h2{
        font-size: 22px;
    }
    .footer__container{
       
        padding: 3rem 1.5rem;
    }
}
@media screen and (max-width:700px) {
    .footer__wrapper{
      
        grid-template-columns: 1fr;
      
    }
    .footer__desc h2{
        font-size: 12px;
    }
}
@media screen and (max-width:700px) {
    .footer__container{
       
        padding: 0.5rem 0.3rem;
    }
    .powered h5{
        font-size: 10px;
        color:rgb(14, 67, 240)
    }
   .copyright h3, h4{
    font-size: 12px;
   }
}
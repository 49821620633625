.accommodation__container{
margin-top:4rem ;
margin-left: 5rem;
margin-right: 5rem;
margin-bottom: 6rem;
}
.acc_head{
 display: flex;
 align-items: center;
 justify-content: center;
 font-size: 65px;
 padding: 3rem 0rem;
}
.accommodation__wrapper{
    display: flex;
    flex-direction: column;
    row-gap: 3rem;
    
}
.accommodation__top, .accommodation__bottom{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
}
.accommodation__top_image, .accommodation__bottom_image{
 display: flex;
 align-items: center;
 justify-content: center;
}
.accommodation__top_image img, .accommodation__bottom_image img{
   width: 100%;
   overflow: hidden;
   object-fit: cover;

   border-radius: 25% 73% 10% 30% / 20% 40% 15% 28%;
   transition: 600ms ease-in-out;
}
.accommodation__top_image img:hover, .accommodation__bottom_image img:hover{
   
   
    border-radius: 25% 23% 10% 30% / 20% 40% 65% 58%;
 
 }
.accommodation__top_content, .accommodation__bottom_content{
   padding: 2rem 2rem 0rem 2rem ;
 }
 .accommodation__top_content h1, .accommodation__bottom_content h1{
    padding: 0rem 3rem 3rem 0rem ;
    font-size: 49px;
  }
  .accommodation__top_content h3{
    font-size: 25px;
    padding: 0.3rem 0;
  }
  .accommodation__top_content ul{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    font-size: 20px;
    place-items: center;
    gap: 1rem;
  }
  .accommodation__top_content ul li{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.3rem;
    background-color: var(--bg-col2);
    color: var(--font-col2);
    padding: 0.5rem;
    width: 100%;
    font-weight: bold;
    border-radius: 14px;
    border: 3px solid var(--bg-col2);
    transition: 600ms ease-in-out;
  }
  .accommodation__top_content ul li:nth-child(2){
    background: transparent;
    color: var(--font-col1);
    transition: 600ms ease-in-out;
  }
  .accommodation__top_content ul li:nth-child(2):hover{
    background: var(--bg-col2);
    color: var(--font-col2);
  }
  .accommodation__top_content ul li:hover{
    background: transparent;
    color: var(--font-col1);
    cursor: pointer;
    animation: zoom 1500ms ease-in-out;
}
.accommodation__bottom_content ul {
    padding-left: 3rem;
}
.accommodation__bottom_content ul li{
padding-left: 1rem;
padding-bottom: 2rem;
text-transform: capitalize;
font-size: 22px;
list-style-type: disc;
}
@keyframes zoom{
    0%,
    20%,
    60%,
    100%{
        transform: scale(1);
    }
    40%{
        transform: scale(1.1);
    }
    80%{
        transform: scale(1.15);
    }
}


@media screen and (max-width:1400px){
    .accommodation__top_content ul{
        grid-template-columns: 1fr 1fr;
    }
}

  @media screen and (max-width:1200px) {
    .accommodation__top, .accommodation__bottom{
        grid-template-columns: 1fr;
    }
    .accommodation__bottom_content{
        order: 2;
    }
  }
  @media screen and (max-width:800px) {
    .accommodation__top_content ul{
        grid-template-columns: 1fr 1fr;
    }
    .accommodation__container{
       
        margin-left: 2rem;
        margin-right: 2rem;
        }
        .accommodation__top_content h1, .accommodation__bottom_content h1{
          
            font-size: 28px;
          }
          .accommodation__top_content h3{
            font-size: 22px;
           
          }
    .acc_head{
        font-size: 35px;
    }
  }
  @media screen and (max-width:550px) {
    .accommodation__top_content ul{
        grid-template-columns:  1fr;
        font-size: 18px;
    }
    .accommodation__container{
       
        margin-left: 0.3rem;
        margin-right: 0.3rem;
        }
        .accommodation__top_content h1, .accommodation__bottom_content h1{
          
            font-size: 24px;
          }
          .accommodation__top_content h3{
            font-size: 19px;
           
          }
    .acc_head{
        font-size: 25px;
    }
    .accommodation__top_content ul{
       
        font-size: 15px;
    }
    .accommodation__bottom_content ul {
        padding-left: 1rem;
    }
    .accommodation__bottom_content ul li{
    padding-left: 0.1rem;
    padding-bottom: 1rem;
    }
    .accommodation__top_content, .accommodation__bottom_content{
        padding: 0.8rem 0.8rem 0rem 0.8rem ;
      }
  }
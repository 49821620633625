.contact__container{
    background-color: var(--bg-col2);
    margin-top: 7rem;
    color: var(--font-col2);
    position: relative;
   padding-bottom: -2rem;
   padding-top: 2rem;
   margin-bottom: 2rem;
}
.contact__wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr;
  gap: 2rem;
    margin: 4rem 4rem;
   
   
}
.contact__head{
    font-size: 50px;
    display: flex;
    justify-content: center;
    padding: 2rem 10rem;
   background-color: #181820;
    position: absolute;
    top: 0px;
    
  left: 50%;
  transform: translate(-50%, -50%);
    opacity: 1;
}
.details{
    display: flex;
    flex-direction: column;
   
}
.form__control label{
    font-size: 18px;
    margin-right: 2rem;
}
.form__control input{
    font-size: 20px;
    border-radius: 5px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: var(--bg-col2);
}
.contact__form{
    background-color: #181820;
  
    
    padding: 2rem;
    border-radius: 10px;
}
.name, .cellNo, .email, .content{
    display: grid;
    grid-template-columns: 1fr 2fr;
    padding-top: 2rem;
}
.form__but{
    padding: 1.3rem 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    background-color: var(--bg-col2);
    border: 3px solid var(--bg-col2);
    border-radius: 20px;
    box-shadow: 2px 2px 4px rgba(224, 5, 5, 0.2), -2px -2px 4px rgba(255, 253, 253, 0.5);
    transition: 400ms ease-in-out;
    color: aliceblue;
}
.form__but:hover{
    background-color: transparent;
}
.form__bt{
    display: flex;
    justify-content: center;
    padding-top: 1.5rem;
    padding-bottom: 0.5rem;
}
.contact__address h3{
padding:1rem 2rem;
}
.contact__address h1{
    padding:0.4rem 4rem;
    }
.content textarea{
width: 100%;
font-size: 18px;
padding: 1rem;
border-radius: 10px;
color: var(--bg-col2);
}
.map{
    display: flex;
    justify-content: center;

}
.map iframe{
   
    width: 100%;
}
@media screen and (max-width:1240px) {
   
    .contact__address h3{
        padding:0.5rem 1rem;
        font-size: 18px;
        }
        .contact__address h1{
            padding:0.4rem 2rem;
            font-size: 29px;
            }

            .contact__head{
                font-size: 30px;
                padding: 2rem 4rem;
            }

}
@media screen and (max-width:1000px) {
   
    .contact__head{
        font-size: 30px;
      
        padding-left: 5rem;
        padding-top: 2rem;
    }
    .contact__wrapper{
       
        grid-template-columns: 1fr;
      
        margin: 1rem 1.5rem;
       
       
    }
    .contact__form{
 
margin-top: 2rem;
 
    }
}
@media screen and (max-width:800px) {
   
    .contact__head{
        font-size: 20px;
        display: flex;
        justify-content: center;
    }
    .name, .cellNo, .email, .content{
        display: grid;
        grid-template-columns: 1fr 2fr;
        padding-top: 2rem;
    }
}
@media screen and (max-width:510px) {
   
    .contact__head{
        font-size: 20px;
        display: flex;
        justify-content: center;
        padding: 2rem 4rem;
    }
    .name, .cellNo, .email, .content{
    
        grid-template-columns: 1fr;
        row-gap: 0.5rem;
       
    }
    .form__but{
        padding: 0.7rem 2rem;
    }
    .map iframe{
   
        height: 300px;
    }
    .form__control label{
        font-size: 12px;
        margin-right: 1rem;
    }
    .form__control input{
        font-size: 12px;
    }
    .contact__form{
           
    padding: 0.8rem;
    border-radius: 0px;
    }
    .contact__address h1{
        padding:0.4rem 1rem;
        font-size: 18px;
        }

        .contact__head{
            font-size: 15px;
        }
        .contact__wrapper{
            padding: 0;
            margin: 0;
        }
}